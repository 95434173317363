import validate from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/middleware/auth.global.js";
import redirect_45global from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/middleware/redirect.global.js";
import trailingslash_45global from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/middleware/trailingslash.global.js";
import manifest_45route_45rule from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  redirect_45global,
  trailingslash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}