export default defineNuxtRouteMiddleware(async (to) => {
  if (!to.path.startsWith("/admin")) {
    return;
  } else {
    try {
      if (import.meta.client) {
        const config = useRuntimeConfig();
        const token = useCookie(config.public.cookieName);

        if (!token?.value) {
          if (to.path.startsWith("/admin/login")) {
            return;
          } else {
            return await navigateTo("/admin/login");
          }
        }

        useState("token", () => token?.value);

        return;
      }
    } catch (error) {
      throw error;
    }
  }
});
