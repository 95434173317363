export const options = {
  "images": false,
  "videos": false,
  "audios": false,
  "iframes": true,
  "native": false,
  "directiveOnly": false,
  "defaultImage": "/logo-sorrifacil-azul-pt.webp",
  "loadingClass": "isLoading",
  "loadedClass": "isLoaded",
  "appendClass": "lazyLoad",
  "observerConfig": {}
}